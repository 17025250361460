import {
  Form,
  useParams,
  useRouteLoaderData,
  Link,
  useLoaderData,
} from "react-router-dom";
import type { Store } from "../../types";

const EditScreen = () => {
  const { screenId } = useParams();
  const { store } = useRouteLoaderData("store") as { store: Store };
  const { video_sets: videoSets } = useLoaderData() as {
    video_sets: { id: number; title: string }[];
  };
  const id = parseInt(screenId!, 10);
  const screen = store.screens.find((s) => s.id === id);
  return (
    <Form method="post">

      <div className="form-group">
        <label>Hostname</label>
        <p className="form-static-control">{screen?.hostname}</p>
      </div>
      <div className="form-group">
        <label htmlFor="type">Description</label>
        <input
          type="text"
          name="screen[screen_type]"
          className="form-control"
          defaultValue={screen?.type}
        />
      </div>

      <div className="form-group">
        <label>Orientation</label>
        <div className="radio">
          <label>
            <input
              type="radio"
              name="screen[orientation]"
              value="horizontal"
              defaultChecked={screen?.orientation === "horizontal"}
            />
            Horizontal
          </label>
        </div>
        <div className="radio">
          <label>
            <input
              type="radio"
              name="screen[orientation]"
              value="vertical"
              defaultChecked={screen?.orientation === "vertical"}
            />
            Vertical
          </label>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="video_set_id">Content</label>
        <select
          name="screen[video_set_id]"
          className="form-control"
          defaultValue={screen?.video_set_id}
        >
          {videoSets.map((vs) => (
            <option value={vs.id} key={vs.id}>
              {vs.title}
            </option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label>Last Deployed</label>
        <p className="form-static-control">
          {screen?.last_deployed_at
            ? new Date(screen?.last_deployed_at).toLocaleString()
            : "N/A"}
        </p>
      </div>

      <div className="form-group">
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      </div>
    </Form>
  );
};

export default EditScreen;
