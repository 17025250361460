import React, { useState } from "react";
import AdThumb from "./AdThumb";
import { useRouteLoaderData, Link } from "react-router-dom";
import type { Ad, Job, Orientation, Screen, User } from "../../types";

const ScreenComponent = ({ screen }: { screen: Screen }) => {
  const { user } = useRouteLoaderData("layout") as {
    user: User;
  };

  return (
    <>
      <h2 id={screen.hostname}>
        {screen.type} <small>{screen.hostname}</small>{" "}
        {screen.offline ? (
          <span className="label label-danger">OFFLINE</span>
        ) : null}{" "}
        {screen.stale && <span className="label label-warning">STALE</span>}{" "}
        <RebootButton screen={screen} user={user} />{" "}
        <DeployButtonForUser screen={screen} />{" "}
        <EditScreenLink screen={screen} />
      </h2>
      <DeployStatus screen={screen} />
      <Videos videos={screen.ads} orientation={screen.orientation} />
    </>
  );
};

const DeployButtonForUser = ({ screen }: { screen: Screen }) => {
  return (
    <Link
      to={`/content/${screen.video_set_id}`}
      state={{ screen: { id: screen.id, hostname: screen.hostname } }}
      className="btn btn-default"
    >
      Deploy
    </Link>
  );
};

export const Videos = ({
  videos,
  orientation,
}: {
  videos: Ad[];
  orientation: Orientation;
}) => {
  let sliceSize, id: "horizontal_id" | "vertical_id";

  if (orientation === "vertical") {
    sliceSize = 6;
    id = "vertical_id";
  } else {
    sliceSize = 3;
    id = "horizontal_id";
  }
  {
    /*
    TODO: do we even need this slicing logic? or will bootstraps column wrapping take care of this for me?
    https://getbootstrap.com/docs/3.4/css/#grid-example-wrapping
    */
  }
  let pairsOfVideos: Ad[][] = [];
  for (let i = 0; i < videos.length; i += sliceSize) {
    pairsOfVideos.push(videos.slice(i, i + sliceSize));
  }
  return (
    <>
      {pairsOfVideos.map((pair) => {
        const key = pair.map((a) => a[id]).join("_");
        return (
          <div className="row" key={key}>
            {pair.map((a) => (
              <AdThumb orientation={orientation} ad={a} key={a[id]} />
            ))}
          </div>
        );
      })}
    </>
  );
};

type RebootProps = {
  screen: Screen;
  user: User;
};

const RebootButton = (props: RebootProps) => {
  const [rebooting, setRebooting] = useState(false);
  const { screen, user } = props;

  const reboot = () => {
    if (window.confirm(`Are you sure you want to reboot ${screen.hostname}?`)) {
      setRebooting(true);
      fetch(`/screens/${screen.id}/reboot`, { method: "POST" })
        .then(() => setRebooting(false))
        .catch(() => {
          setRebooting(false);
          alert(`failed to reboot ${screen.hostname}`);
        });
    }
  };

  if (screen.offline) {
    return null;
  } else if (user.admin) {
    return (
      <button className="btn btn-default" disabled={rebooting} onClick={reboot}>
        {rebooting ? "Rebooting..." : "Reboot"}
      </button>
    );
  } else {
    return null;
  }
};

const hasPendingDeploy = (
  screen: Screen
): screen is Omit<Screen, "deploy_job"> & {
  // these type guards just tell TS that deploy_job and scheduled_at are not
  // undefined
  deploy_job: Omit<Job, "scheduled_at"> & { scheduled_at: string };
} => {
  const deployJob = screen.deploy_job;
  return (
    deployJob != null &&
    deployJob.completion_status == undefined &&
    deployJob.scheduled_at != null
  );
};

const localeString = (date: string) =>
  new Date(Date.parse(date)).toLocaleString();

export const DeployStatus = ({ screen }: { screen: Screen }) => {
  // scheduled job
  if (hasPendingDeploy(screen)) {
    const deployJob = screen.deploy_job;
    const scheduledAt = localeString(deployJob.scheduled_at);
    if (deployJob.attempts > 0) {
      // TODO: cancel and try again now buttons?
      return (
        <div className="row">
          <div className="col-md-8">
            <div className="alert alert-danger">
              The last {deployJob.attempts} attempts to deploy this screen
              failed. The system will try again at {scheduledAt}.
            </div>
          </div>
        </div>
      );
    } else {
      // TODO: cancel button
      return (
        <div className="row">
          <div className="col-md-8">
            <div className="alert alert-info">
              This screen is scheduled to deploy at {scheduledAt}.
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
};

const EditScreenLink = ({ screen }: { screen: Screen }) => {
  return (
    <Link to={`screens/${screen.id}/edit`} className="btn btn-default">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="currentColor"
        style={{ verticalAlign: "bottom" }}
        className="bi bi-gear"
        viewBox="0 0 16 16"
      >
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
      </svg>
    </Link>
  );
};

export default ScreenComponent;
