import React from 'react'
import { useRouteLoaderData } from "react-router-dom";

const LandingScreen = () => {
  const { user } = useRouteLoaderData("layout");
  return (
    <div className="jumbotron">
      <h1>{user.organization} Screens</h1>
      <p>To get started, choose a location from the header bar above.</p>
    </div>
  )
}

export default LandingScreen
