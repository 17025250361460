import React from "react";
import { Outlet, useLoaderData, useParams } from "react-router-dom";

const StoreView = (props) => {
  const { store } = useLoaderData();

  return store ? (
    <div>
      <h1>{store.location}</h1>
      <Outlet />
    </div>
  ) : (
    <b>Couldnt find this store.</b>
  );
};

export default StoreView;

