import { useState } from "react";
import {
  Form,
  Link,
  useLoaderData,
  useLocation,
  useParams,
} from "react-router-dom";
import type { Ad, Screen } from "../types";
import AdThumb from "./Store/AdThumb";
import { DeployStatus, Videos } from "./Store/Screen";

const VideoSet = () => {
  const { title, videos, screens } = useLoaderData() as {
    title: string | null;
    videos: Ad[];
    screens: Screen[];
  };
  const { videoSetId } = useParams();
  const location = useLocation();
  const [showScheduledAt, setShowScheduledAt] = useState(false);

  const currentScreen = (location.state as { screen: Screen } | null)?.screen;
  const firstScreen = screens[0] as Screen | undefined;
  const orientation =
    currentScreen?.orientation ?? firstScreen?.orientation ?? "horizontal";

  return (
    <>
      <h1>{title ?? `Content ${videoSetId}`}</h1>

      <Videos videos={videos} orientation={orientation} />

      <p>This content is assigned to the following {screens.length} screens:</p>

      <ul>
        {screens.map((s) => (
          <li key={s.id}>
            <StoreLink screen={s} /> <small>{s.hostname}</small>
            {s.stale ? (
              <>
                {" "}
                <span className="label label-warning">stale</span>
              </>
            ) : null}
            {s.offline ? (
              <>
                {" "}
                <span className="label label-danger">offline</span>
              </>
            ) : null}
            <DeployStatus screen={s} />
          </li>
        ))}
      </ul>

      <Form
        method="post"
        state={location.state}
        style={{ marginBottom: "32px" }}
      >
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              name="scheduled_deploy"
              onChange={(e) => {
                setShowScheduledAt(e.target.checked);
              }}
            />{" "}
            Schedule future deployment
          </label>
        </div>
        {showScheduledAt ? (
          <div className="form-group">
            <label htmlFor="deploy_at">Schedule deployment at</label>
            <input
              className="form-control"
              id="deploy_at"
              name="deploy_at"
              type="datetime-local"
              style={{ width: "auto" }}
            />
          </div>
        ) : null}

        <button
          type="submit"
          name="video_set_id"
          value={videoSetId}
          className="btn btn-primary"
        >
          Deploy to all stale locations
        </button>
        {currentScreen ? (
          <>
            {" "}
            <button
              type="submit"
              name="screen_id"
              value={currentScreen.id}
              className="btn btn-default"
            >
              Deploy to {currentScreen.hostname}
            </button>
          </>
        ) : (
          ""
        )}
      </Form>
    </>
  );
};

const StoreLink = ({ screen }: { screen: Screen }) => {
  const { store } = screen;
  return store === undefined ? null : (
    <Link to={`/store/${store.id}#${screen.hostname}`}>{store.name}</Link>
  );
};

export default VideoSet;
