import Screen from "./Screen";
import { Link, useRouteLoaderData } from "react-router-dom";

const Screens = (props) => {
  const { store } = useRouteLoaderData("store");
  const videoSets = store.video_sets;

  return (
    <div>
      <Link
        to={`/store/${store.id}/menu`}
        className="btn btn-primary"
        role="button"
      >
        Edit Prices
      </Link>

      {store.screens.map((screen) => {
        // attaching videos directly to screen for ease of use
        const augmentedScreen = {
          ...screen,
          ads: videoSets[screen.video_set_id].videos,
        }
        return (
          <Screen
            screen={augmentedScreen}
            key={augmentedScreen.id}
          />
        );
      })}
    </div>
  );
};

export default Screens;
