import React, { useEffect, useRef, useState } from "react";
import {
  Outlet,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import "./App.css";

const App = (props) => {
  const params = useParams();
  const signoutForm = useRef(null);
  const navigate = useNavigate();
  const { user, stores } = useLoaderData();

  const handleLocationChange = (e) => {
    const storeId = e.target.value;
    console.log("navigating to", storeId, "...");
    // should just handle the no id w/ a redirect in the loader
    navigate(storeId !== "-1" ? `store/${storeId}` : "/");
  };

  const options = stores
    .sort((a, b) => a.location.localeCompare(b.location))
    .map((store) => (
      <option key={store.id} value={store.id}>
        {store.location}
      </option>
    ));

  return (
    <>
      <nav className="apptv-navbar">
        <div className="navbar-header">
          <a className="navbar-brand" href="/">
            {user.organization} Screens
          </a>
        </div>

        <form className="navbar-form">
          <div className="form-group">
            <select
              className="form-control"
              onChange={handleLocationChange}
              value={params.storeId || "-1"}
            >
              <option value="-1">Choose a location...</option>
              {options}
            </select>
          </div>
        </form>

        <form ref={signoutForm} action="/users/sign_out" method="POST">
          <input type="hidden" name="_method" value="delete" />
          <button
            className="btn btn-link hidden-xs"
            style={{ padding: "15px 0", lineHeight: "20px" }}
          >
            Logout
          </button>
        </form>
      </nav>

      <div className="container">
        <Outlet />
      </div>

      <ScrollRestoration />
    </>
  );
};

export default App;
