import React from "react";
import { Link } from "react-router-dom";
import type { Ad, Orientation } from "../../types";

type AdThumbProps = {
  ad: Ad;
  orientation: Orientation;
};

const AdThumb = ({ ad, orientation }: AdThumbProps) => {
  let width, className;
  const { horizontal_id, vertical_id } = ad;
  let id;

  if (orientation === "vertical") {
    className = "col-md-2";
    id = vertical_id;
  } else {
    width = "350";
    className = "col-md-4";
    id = horizontal_id;
  }
  const thumbnail = `/videos/${id}_thumb.jpg`; // FIXME: path munging is gross
  return (
    <div className={className}>
      <Link to={`/video/${id}`} className="thumbnail">
        <img width={width} src={thumbnail} alt={ad.description} />
      </Link>
    </div>
  );
};

export default AdThumb;
