import React from "react";
import ReactDOM from "react-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  json,
  redirect,
  RouterProvider,
  Route,
  useRouteError,
} from "react-router-dom";
import App from "./App";
import LandingScreen from "./views/LandingScreen";
import StoreView from "./views/StoreView";
import Screens from "./views/Store/Screens";
import MenuEditor from "./views/Store/MenuEditor";
import EditScreen from "./views/Store/EditScreen";
import VideoPlayer from "./views/VideoPlayer";
import VideoSet from "./views/VideoSet";
import "bootstrap/dist/css/bootstrap.css";
//import 'bootstrap/dist/css/bootstrap-theme.css'
//import './index.css';

const layoutLoader = async ({ request }) => {
  const response = await fetch("/api/app.json");
  if (!response.ok) {
    throw response;
  }
  return response;
};
const storeLoader = async ({ params, request }) =>
  fetch(`/api/stores/${params.storeId}.json`);
const menuLoader = async ({ params, request }) =>
  fetch(`/api/stores/${params.storeId}/menu.json`);
const videoSetLoader = async ({ params, request }) =>
  fetch(`/api/video_sets/${params.videoSetId}.json`);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
      loader={layoutLoader}
      id="layout"
      errorElement={<ErrorBoundary />}
    >
      <Route element={<LandingScreen />} index={true} />
      <Route
        path="store/:storeId"
        element={<StoreView />}
        loader={storeLoader}
        id="store"
      >
        <Route element={<Screens />} index={true} />
        <Route
          path="screens/:screenId/edit"
          element={<EditScreen />}
          loader={async () => fetch(`/api/video_sets`)}
          action={async ({ params, request }) => {
            await fetch(`/api/screens/${params.screenId}`, {
              method: "POST",
              body: await request.formData(),
            });
            return redirect(`/store/${params.storeId}`);
          }}
        />
        <Route
          path="menu"
          element={<MenuEditor />}
          loader={menuLoader}
          action={async ({ params, request }) => {
            // TODO: error handling?
            // TODO: we could split this into multiple backend requests
            // again... might clean things up a bit?
            fetch(`/api/stores/${params.storeId}/menu`, {
              method: "POST",
              body: await request.formData(),
            }).then((response) => {
              if (response.ok) {
                alert(
                  "Your menus are now being generated. Please check back in about an hour."
                );
                // TODO: alert here? what's th right way to confirm something went well?
              } else {
                alert("prices failed to save");
              }
            });
            return redirect(`/store/${params.storeId}/menu`);
          }}
        />
      </Route>
      <Route path="video/:videoId" element={<VideoPlayer />} />
      <Route
        path="content/:videoSetId"
        element={<VideoSet />}
        loader={videoSetLoader}
        action={async ({ request }) => {
          const formData = await request.formData();
          const body = JSON.stringify({
            deploy_at: formData.get("deploy_at"),
            utc_offset_minutes: new Date().getTimezoneOffset(),
          });
          let url;
          if (formData.get("screen_id") != null) {
            url = `/screens/${formData.get("screen_id")}/deploy`;
          } else if (formData.get("video_set_id") != null) {
            url = `/video_sets/${formData.get("video_set_id")}/deploy`;
          } else {
            throw new Error("i don't know how to deploy this");
          }

          const response = await fetch(url, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body,
          });
          return response.ok
            ? json({ status: "OK" })
            : new Response(response.body, { status: response.status });
        }}
      />
    </Route>
  )
);

function ErrorBoundary() {
  const error = useRouteError();
  // TODO: i'm assuming error is always a Response right now... should guard
  // this with isResponseError
  switch (error.status) {
    case 401:
      window.location = "/users/sign_in";
      return (
        <p>
          You've been signed out. <a href="/users/sign_in">Log in again.</a>
        </p>
      );
    default:
      console.error(error);
      return <p>Something went wrong.</p>;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
