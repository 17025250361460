import { Form, useLoaderData } from "react-router-dom";
import "./MenuEditor.css";

const MenuEditor = (props) => {
  const { menu, shared_with: sharedWith } = useLoaderData();
  return (
    <>
      <SharedMenuNotice sharedWith={sharedWith} />
      <Form method="post" className="form-horizontal menu-form">
        {menu.map((entry) => {
          return entry.type === "heading" ? (
            <h2 key={entry.label}>{entry.label}</h2>
          ) : entry.type === "input" ? (
            <div key={entry.fields[0].name} className="form-group">
              <label
                className="col-sm-4 control-label"
                htmlFor={entry.fields[0].name}
              >
                {entry.label}
              </label>
              {entry.fields.map((field) => (
                <div key={field.name} className="col-sm-2">
                  <input
                    id={field.name}
                    name={field.name}
                    className="form-control"
                    placeholder={field.label}
                    defaultValue={field.value}
                  />
                </div>
              ))}
            </div>
          ) : null;
        })}
        <div className="submit-bar">
          <input type="submit" className="btn btn-primary" value="Save" />
        </div>
      </Form>
    </>
  );
};

const SharedMenuNotice = ({ sharedWith }) =>
  sharedWith.length > 0 ? (
    <div className="alert alert-warning">
      <strong>NOTE</strong>: This menu is also shared with the following
      locations:
      <ul>
        {sharedWith.map((s) => (
          <li key={s.id}>{s.location}</li>
        ))}
      </ul>
    </div>
  ) : null;

export default MenuEditor;
