import React from "react";
import {useParams} from 'react-router-dom'

const VideoPlayer = (props) => {
  const {videoId} = useParams()
  const src = `/videos/${videoId}.mp4`;
  const poster = `/videos/${videoId}_poster.jpg`;
  return (
    <div className="row">
      <video
        controls
        autorun="true"
        muted
        preload="auto"
        poster={poster}
        style={{width: "100%"}}
      >
        <source src={src} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPlayer;
